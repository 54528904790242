import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckBox from 'components/CheckBox';
import TextHolder from 'components/TextHolder';
import InputHolder from 'components/InputHolder';
import { getTranslatedField } from 'utils/helpers';
import TextareaHolder from 'components/TextareaHolder';
import Spinner from 'components/Spinner';
import DatePicker from 'components/DatePicker';
import FileInput from 'components/FileInput';
import { modals } from 'config/constants';
import AddHighlightCover from 'components/Modals/AddHighlightCover';
import { useZustandAuth, useZustandStore } from 'zustandStore';
import { useMainEvents } from 'hooks/events';
import { useLinkedCulturalStore } from 'zustandStore/linkedCulturalsStore';

const eventGrades = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function EventsManager(props) {
    const { highlighEvent } = useZustandStore();
    const { isAuthed } = useZustandAuth();
    const mainEvents = useMainEvents(isAuthed);
    const { culturalContent } = useLinkedCulturalStore();
    // const [allowCheckHighlight, setAllowCheckHighlight] = useState(!!props.event?.is_highlight);
    const renderCulturals = (cultural) => (
        <CheckBox
            checked={cultural?.checked}
            key={cultural?.id}
            id={cultural?.id}
            label={cultural?.title}
            onChange={props.onCulturalToggle}/>
    );

    const renderGrades = (grade) => {
        return (
            <option selected={props.event?.grade === grade} key={grade}>
                {grade}
            </option>
        );
    };

    const _mainEventCheck = useRef();

    useEffect(() => {
        if (props.event?.is_main_event) {
            _mainEventCheck.current.checked = 1;
        } else {
            _mainEventCheck.current.checked = 0;
        }
    }, [props.event?.is_main_event]);

    const _highlightEventCheck = useRef();

    useEffect(() => {
        if (props.event?.is_highlight) {
            _highlightEventCheck.current.checked = 1;
        } else {
            _highlightEventCheck.current.checked = 0;
        }
    }, [props.event?.is_highlight]);

    // useEffect(() => {
    //     if (highlighEvent.cover) {
    //         setAllowCheckHighlight(true);
    //     }
    // }, [highlighEvent.cover]);

    return (
        <div className='w-full'>
            <div className='form-control space-y-3 border-none w-fit'>
                <label className='cursor-pointer label space-x-6'>
                    <input
                        ref={_mainEventCheck}
                        id='is_main_event'
                        type='checkbox'
                        disabled={mainEvents.data?.length >= 3}
                        onChange={props.onEventChange}
                        // checked={!!props.event?.is_main_event ?? ''}
                        className='checkbox checkbox-primary'/>
                    <span className='label-text text-brand-secondary font-semibold'>
                        Set as featured event?
                    </span>
                </label>
                <div className='cursor-pointer label space-x-6'>
                    <input
                        ref={_highlightEventCheck}
                        id='is_highlight'
                        type='checkbox'
                        onChange={props.onEventChange}
                        // style={{
                        //     pointerEvents: allowCheckHighlight ? 'auto' : 'none'
                        // }}
                        // checked={!!props.event?.is_main_event ?? ''}
                        className='checkbox checkbox-primary'/>
                    <span className='label-text text-brand-secondary font-semibold'>
                        Set as highlight event?
                    </span>
                </div>
            </div>
            <div className='form-control space-y-3 border-none'>
                <TextHolder>Event Media</TextHolder>
                <div className='grid space-y-3'>
                    <FileInput onChange={props.onEventChange} />
                    <InputHolder
                        clear={props.isPublishing || props.isUpdating}
                        placeholder='Youtube video url...'
                        id='media-video'
                        onChange={props.onEventChange}
                        defaultValue={props?.event?.media?.find(
                            (m) => m?.type === 'VIDEO'
                        )?.url}/>
                    <div className='flex space-x-3'>
                        <label htmlFor={modals.cover} className='cursor-pointer'>
                            <button
                                ref={_highlightEventCheck}
                                id='highlight_image'
                                onChange={props.onEventChange}
                                style={{
                                    pointerEvents: 'none'
                                }}
                                className='btn btn-primary !mt-0'>
                                <span className='label-text  font-semibold'>
                        Add highlight Image
                                </span>
                            </button>
                        </label>
                        {
                            (props.event?.cover_image_url || highlighEvent.cover) &&
                            <img src={highlighEvent.cover ?? props.event?.cover_image_url} className='rounded-md h-28 w-16 bg-contain' alt=''/>
                        }

                    </div>
                </div>
            </div>
            <div className='form-control space-x-6 py-3 border-none flex flex-row items-center w-64'>
                <select
                    onChange={props.onEventChange}
                    id='grade'
                    className='select w-fit select-sm select-primary'>
                    <option disabled={true} selected={true}>
                        Grade
                    </option>
                    {eventGrades.map(renderGrades)}
                </select>

                <InputHolder
                    clear={props.isPublishing || props.isUpdating}
                    type='number'
                    defaultValue={props.event?.price}
                    placeholder='Tarif €...'
                    onChange={props.onEventChange}
                    id='price'/>
            </div>

            <div className='form-control border-none flex flex-col space-y-3'>
                <InputHolder
                    clear={props.isPublishing || props.isUpdating}
                    defaultValue={props.event?.address}
                    placeholder='Street...'
                    onChange={props.onEventChange}
                    id='address'/>
                <InputHolder
                    clear={props.isPublishing || props.isUpdating}
                    defaultValue={props.event?.postal_code}
                    placeholder='Zipe Code...'
                    onChange={props.onEventChange}
                    id='postal_code'/>
                <InputHolder
                    clear={props.isPublishing || props.isUpdating}
                    type='tel'
                    defaultValue={props.event?.phone}
                    placeholder='Phone number...'
                    onChange={props.onEventChange}
                    id='phone'/>
                <InputHolder
                    clear={props.isPublishing || props.isUpdating}
                    defaultValue={props.event?.city}
                    placeholder='City...'
                    onChange={props.onEventChange}
                    id='city'/>
            </div>

            <div className='form-control border-none flex flex-col space-y-3'>
                <InputHolder
                    clear={props.isPublishing || props.isUpdating}
                    defaultValue={props.event?.event_provider}
                    placeholder='Event Provider...'
                    onChange={props.onEventChange}
                    id='event_provider'/>
                <InputHolder
                    clear={props.isPublishing || props.isUpdating}
                    defaultValue={props.event?.google_map_url}
                    placeholder='Google map url...'
                    onChange={props.onEventChange}
                    id='google_map_url'/>
                <InputHolder
                    clear={props.isPublishing || props.isUpdating}
                    defaultValue={props.event?.link}
                    placeholder='Event link...'
                    onChange={props.onEventChange}
                    id='link'/>
            </div>

            <div className='form-control space-y-3 border-none'>
                <DatePicker
                    endDate={props.endDate || props.event?.end_date?.split('T')[0]}
                    startDate={props.startDate ||
                        props.event?.start_date?.split('T')[0]}
                    onEventChange={props.onEventChange}/>
            </div>

            <div className='form-control space-y-3 border-none'>
                <TextHolder>Event Title</TextHolder>
                <div className='flex space-x-6'>
                    <InputHolder
                        clear={props.isPublishing || props.isUpdating}
                        defaultValue={getTranslatedField(
                            props.event?.translations
                        )('fr', 'title')}
                        placeholder='Fr...'
                        onChange={props.onEventChange}
                        id='title-fr'/>
                    <InputHolder
                        clear={props.isPublishing || props.isUpdating}
                        defaultValue={getTranslatedField(
                            props.event?.translations
                        )('en', 'title')}
                        placeholder='En...'
                        onChange={props.onEventChange}
                        id='title-en'/>
                </div>
            </div>

            <div className='form-control space-y-3 border-none'>
                <TextHolder>Event Description</TextHolder>
                <div className='flex space-x-6'>
                    <TextareaHolder
                        clear={props.isPublishing || props.isUpdating}
                        defaultValue={getTranslatedField(
                            props.event?.translations
                        )('fr', 'description')}
                        placeholder='Fr...'
                        onChange={props.onEventChange}
                        id='description-fr'/>
                    <TextareaHolder
                        clear={props.isPublishing || props.isUpdating}
                        defaultValue={getTranslatedField(
                            props.event?.translations
                        )('en', 'description')}
                        placeholder='En...'
                        onChange={props.onEventChange}
                        id='description-en'/>
                </div>
            </div>

            <div className='form-control space-y-3 border-none'>
                <div className='min-h-8'>
                    {props.isLoadingCulturals ? <Spinner /> : null}
                    {culturalContent.map(renderCulturals)}
                    {!culturalContent.length ? (
                        <span>No linked culturals</span>
                    ) : null}
                </div>
            </div>

            <div className='w-full flex justify-end pt-6 space-x-10'>
                <button
                    disabled={!props?.event}
                    onClick={props.submitUpdates}
                    className={`btn btn-primary focus:btn-primary text-brand-accent focus:text-brand-accent ${
                        props.isUpdating ? 'loading' : ''
                    }`}>
                    Update Event
                </button>
                <button
                    onClick={props.publish}
                    className={`btn btn-primary focus:btn-primary text-brand-accent focus:text-brand-accent ${
                        props.isPublishing ? 'loading' : ''
                    }`}>
                    Publish Event
                </button>
            </div>

            <AddHighlightCover
                id={modals.cover}/>
        </div>
    );
}

EventsManager.propTypes = {
    isUpdating: PropTypes.bool,
    isPublishing: PropTypes.bool,
    isLoadingCulturals: PropTypes.bool,
    event: PropTypes.object,
    publish: PropTypes.func,
    submitUpdates: PropTypes.func,
    onEventChange: PropTypes.func,
    onCulturalToggle: PropTypes.func,
    endDate: PropTypes.string,
    startDate: PropTypes.string
};

export default EventsManager;
