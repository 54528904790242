/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
import React from 'react';
import { useZustandStore } from 'zustandStore';
import useImagePicker from './useImagePicker';
import { getTranslatedField } from 'utils/helpers';
import { useCreateEvent, useUpdateEvent } from './events';
import { useDataForm } from './useDataForm';
import { endPoints, queryClient } from 'api';
import { useLinkedCulturalStore } from 'zustandStore/linkedCulturalsStore';

const validateEvent = ({ activeCategory, newEvent }) => {
    let message = '';

    if (!Object.keys(activeCategory)?.length) {
        message = 'You have not selected any category!';
    } else if (
        !Object.keys(activeCategory).includes('3') &&
        !Object.keys(activeCategory).includes('4') &&
        activeCategory['1']?.id !== null
    ) {
        message = 'You must publish the event under level 3 or 4 category';
    } else if (!newEvent.title?.en || !newEvent.title?.fr) {
        message = 'All title fields are required';
    } else if (!newEvent.description?.en || !newEvent.description?.fr) {
        message = 'All description fields are required';
    }

    return message;
};

function useNewEvent(event) {
    const { pickImage } = useImagePicker();
    const {
        activeCategory,
        createAlert,
        setEventMedia,
        eventMedia,
        classification,
        resetEvent,
        clearClassification,
        activeTab,
        clearHighlightCover,
        highlighEvent
    } = useZustandStore();

    const { isCreatingEvent, onCreateEvent } = useCreateEvent();
    const { isUpdatingEvent, onUpdateEvent } = useUpdateEvent();

    const { toggleCulturalContent, resetCulturalContent, culturalContent } =
        useLinkedCulturalStore();

    const _media = React.useRef([]);

    React.useEffect(() => {
        const videoType = event?.media?.find((m) => m.type === 'VIDEO');

        if (videoType) {
            _media.current = [videoType];
        } else {
            _media.current = [];
        }
    }, [event]);

    const {
        dataForm: newEvent,
        createData: createEventForm,
        clearData: clearEventForm
    } = useDataForm();
    const {
        dataForm: title,
        clearData: clearTitle,
        createData: createTitle
    } = useDataForm();
    const {
        dataForm: description,
        clearData: clearDescription,
        createData: createDescription
    } = useDataForm();

    const onEventSuccess = async (categories, title, eventId) => {
        await Promise.all(
            categories.map(async (category) => {
                await queryClient.invalidateQueries({
                    queryKey: [endPoints.events.list(category)]
                });
            })
        );

        if (eventId) {
            await queryClient.invalidateQueries({ queryKey: [endPoints.events.event(eventId)] });
        }

        resetEvent();
        clearClassification();

        clearEventForm();
        clearDescription();
        clearTitle();

        resetCulturalContent();

        createAlert({
            type: 'alert-success',
            message: `${title} has been updated!`
        });
    };

    const onEventChange = (evt) => {
        const idData = evt?.target?.id?.split('-');
        const { value: inputValue, checked } = evt.target;

        const pastedValue = evt.clipboardData?.getData('text/plain') ?? '';

        const value = inputValue || pastedValue;

        const key = idData[0];

        if (value === 'on') {
            if (!checked && key === 'is_highlight') {
                clearHighlightCover();
            }

            createEventForm(key, checked);
        } else if (key === 'media') {
            const subKey = idData[1];

            if (evt?.target?.files && subKey === 'image') {
                pickImage(evt?.target?.files[0], true);
            } else {
                const regx =
                    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;

                const isValid = value?.match(regx);

                if (isValid && isValid[2].length === 11) {
                    const videoURL =
                        'https://www.youtube.com/embed/' + isValid[2];

                    _media.current = [
                        ..._media.current.filter((m) => !m.type === 'VIDEO')
                    ];

                    _media.current.push({
                        type: 'VIDEO',
                        url: videoURL
                    });

                    setEventMedia({ video: videoURL });
                } else {
                    _media.current = [
                        ..._media.current.filter((m) => !m.type === 'VIDEO')
                    ];
                }
            }
        } else if (idData.length > 1) {
            const subKey = idData[1];

            if (key === 'title') {
                createTitle(subKey, value);
            }

            if (key === 'description') {
                createDescription(subKey, value);
            }
        } else {
            createEventForm(key, value);
        }
    };

    const onCulturalToggle = (evt) => {
        const { id } = evt.target;

        toggleCulturalContent(+id);
    };

    const createEventData = () => {
        let data = {};

        const newEventData = JSON.parse(JSON.stringify(newEvent ?? {}));
        const newEventTitle = JSON.parse(JSON.stringify(title ?? {}));
        const newEventDescription = JSON.parse(
            JSON.stringify(description ?? {})
        );

        let media = [];

        if (event) {
            const pureEvent = {};

            for (const [key, value] of Object.entries(event)) {
                if (value) {
                    Object.assign(pureEvent, { [key]: value });
                }
            }

            data = {
                ...pureEvent,
                ...newEventData,
                title: newEventTitle,
                description: newEventDescription
            };

            media.push(...pureEvent.media);

            delete data.opening_hours_url;

            if (!data.description?.fr) {
                data.description.fr = getTranslatedField(
                    pureEvent.translations
                )('fr', 'description');
            }

            if (!data.description?.en) {
                data.description.en = getTranslatedField(
                    pureEvent.translations
                )('en', 'description');
            }

            if (!data.title.fr) {
                data.title.fr = getTranslatedField(pureEvent.translations)(
                    'fr',
                    'title'
                );
            }

            if (!data.title.en) {
                data.title.en = getTranslatedField(pureEvent.translations)(
                    'en',
                    'title'
                );
            }

            if (data.cultural_contents?.length) {
                data.cultural_contents = data.cultural_contents?.map(
                    (cltrl, order) => ({ id: cltrl.id, order })
                );
            }
        } else {
            data = {
                ...newEventData,
                title: newEventTitle,
                description: newEventDescription
            };
        }

        const message = validateEvent({ activeCategory, newEvent: data });

        if (message) {
            createAlert({ type: 'alert-error', message });

            return;
        }

        if (eventMedia.img) {
            media = media.filter((m) => m.type !== 'IMAGE');
            media.push({
                type: 'IMAGE',
                url: eventMedia.img
            });
        }

        if (_media.current.length) {
            media = media.filter((m) => m.type !== 'VIDEO');
            media.push(..._media.current);
        } else {
            media = media.filter((m) => m.type !== 'VIDEO');
        }

        data.latitude = '12463';
        data.longitude = '458125';
        data.status = 'ACTIVE';

        data.media = media;

        if (culturalContent.length) {
            data.cultural_contents = culturalContent
                .filter(({ checked }) => checked)
                .map(({ id }, order) => ({ id, order }));
        }

        if (highlighEvent.cover) {
            data.cover_image_url = highlighEvent.cover;
        }

        return data;
    };

    const onSubmitEvent = async () => {
        const data = createEventData();

        if (data) {
            const classOneEvent = classification.class_1;

            const activeCategoryArr = [...Object.values(classOneEvent)];

            if (!activeCategoryArr.length) {
                createAlert({
                    type: 'alert-error',
                    message: 'Oops! Please re-select the categories again!'
                });

                return;
            }

            data.categories = activeCategoryArr.map((c) => c.id);
            data.class_level = 1;

            if (!data.grade) {
                data.grade = '1';
            }

            const categories = [...data.categories];

            await onCreateEvent(data, {
                onSuccess: async (res, payload) => {
                    const classOneEventId = res.id;

                    if (Object.values(classification).length === 1) {
                        await Promise.all(
                            categories.map(async (category) => {
                                await queryClient.invalidateQueries({
                                    queryKey: [endPoints.events.list(category)]
                                });
                            })
                        );

                        resetEvent();
                        clearClassification();

                        clearEventForm();
                        clearDescription();
                        clearTitle();

                        resetCulturalContent();

                        createAlert({
                            type: 'alert-success',
                            message: 'The event has been created!'
                        });
                    }

                    await Promise.all(
                        Object.values(classification)
                            .filter((_, idx) => idx !== 0)
                            .map(async (_class, idx) => {
                                const activeCategoryArr = [
                                    ...Object.values(_class)
                                ];

                                const _data = { ...payload };

                                _data.categories = activeCategoryArr.map(
                                    (c) => c.id
                                );
                                _data.class_level = idx + 2;
                                _data.class_one_event_id = classOneEventId;
                                categories.push(..._data.categories);

                                await onCreateEvent(_data, {
                                    onSuccess: async () => {
                                        await Promise.all(
                                            categories.map(async (category) => {
                                                await queryClient.invalidateQueries(
                                                    {
                                                        queryKey: [
                                                            endPoints.events.list(
                                                                category
                                                            )
                                                        ]
                                                    }
                                                );
                                            })
                                        );

                                        resetEvent();
                                        clearClassification();

                                        clearEventForm();
                                        clearDescription();
                                        clearTitle();

                                        resetCulturalContent();

                                        createAlert({
                                            type: 'alert-success',
                                            message:
                                                'The event has been created!'
                                        });
                                    }
                                });
                            })
                    );
                }
            });
        }
    };

    const handleOnEventUpdate = async () => {
        const data = createEventData();

        if (data) {
            if (!data.grade) {
                data.grade = '1';
            } else {
                data.grade = `${data.grade}`;
            }

            const activeClassLevel = +activeTab.split('class_')[1];
            const rawActiveEvent =
                event.classes.find((e) => e.class_level === activeClassLevel) ??
                event;

            const activeEvent = {};

            for (const [key, value] of Object.entries(rawActiveEvent)) {
                if (value) {
                    Object.assign(activeEvent, { [key]: value });
                }
            }

            const currentEventClass =
                classification[`class_${activeEvent.class_level}`];

            const activeCategoryArr = [
                ...Object.values(currentEventClass ?? {})
            ];

            if (!activeCategoryArr.length) {
                createAlert({
                    type: 'alert-error',
                    message: 'Oops! Please re-select the categories again!'
                });

                return;
            }

            data.categories = activeCategoryArr
                .map((c) => c.id)
                ?.filter((id) => !!id);
            data.class_level = activeEvent.class_level;

            const categories = [...data.categories];
            const otherClassesOldCategories = event.classes
                .map((_class) => _class.categories)
                ?.flat();
            const oldCategories = [
                ...event.categories.map((c) => c.id),
                ...otherClassesOldCategories.map((c) => c.id)
            ];

            if (!data.categories.length) {
                data.categories = null;
            }

            const otherEvents = event.classes.filter(
                (e) => e.id !== activeEvent.id
            );

            if (
                activeEvent.id !== event.id &&
                !otherEvents.find((e) => e.id === event.id)
            ) {
                otherEvents.push(event);
            }

            const newActiveEventData = { ...activeEvent, ...data };

            if (newActiveEventData.class_level === 1) {
                delete newActiveEventData.class_one_event_id;
            }

            const oldClasses = [activeEvent, ...otherEvents].map(
                (evt) => `class_${evt.class_level}`
            );

            const newClasses = [];

            if (otherEvents.length + 1 < Object.keys(classification).length) {
                newClasses.push(
                    ...Object.keys(classification).reduce((acc, curr) => {
                        if (!oldClasses.find((_c) => _c === curr)) {
                            acc.push(curr);
                        }

                        return acc;
                    }, [])
                );
            }

            await onUpdateEvent(
                { data: newActiveEventData, id: activeEvent.id },
                {
                    onSuccess: async (res, payload) => {
                        if (!otherEvents.length && !newClasses.length) {
                            await onEventSuccess(
                                [...categories, ...oldCategories],
                                res.title,
                                activeEvent.id
                            );
                        }

                        await Promise.all(
                            otherEvents.map(async (evt) => {
                                const activeCategoryArr = [
                                    ...Object.values(
                                        classification[
                                            `class_${evt.class_level}`
                                        ]
                                    )
                                ];

                                const _data = {
                                    ...payload.data,
                                    class_level: evt.class_level
                                };

                                _data.categories = activeCategoryArr
                                    .map((c) => c.id)
                                    ?.filter((id) => !!id);

                                categories.push(..._data.categories);

                                if (!_data.categories.length) {
                                    _data.categories = null;
                                }

                                const newEventData = { ...evt, ..._data };

                                if (newEventData.class_level === 1) {
                                    delete newEventData.class_one_event_id;
                                }

                                await onUpdateEvent(
                                    { data: newEventData, id: evt.id },
                                    {
                                        onSuccess: async () => {
                                            if (!newClasses.length) {
                                                await onEventSuccess(
                                                    [
                                                        ...categories,
                                                        ...oldCategories
                                                    ],
                                                    res.title,
                                                    activeEvent.id
                                                );
                                            }
                                        }
                                    }
                                );
                            })
                        );
                    }
                }
            );

            if (newClasses.length) {
                await Promise.all(
                    newClasses.map(async (_class, idx) => {
                        const activeCategoryArr = [
                            ...Object.values(classification[_class])
                        ];

                        const _data = { ...newActiveEventData };

                        _data.categories = activeCategoryArr.map((c) => c.id);
                        _data.class_level = +_class.split('class_')[1];
                        _data.class_one_event_id =
                            activeEvent.class_level === 1
                                ? activeEvent.id
                                : activeEvent?.class_one_event_id ||
                                  otherEvents?.find(
                                      (evt) => evt.class_one_event_id
                                  )?.class_one_event_id;
                        categories.push(..._data.categories);

                        await onCreateEvent(_data, {
                            onSuccess: async (res) => {
                                onEventSuccess(
                                    [...categories, ...oldCategories],
                                    res.title,
                                    activeEvent.id
                                );
                            }
                        });
                    })
                );
            }
        }
    };

    return {
        onCulturalToggle,
        onEventChange,
        handleOnEventUpdate,
        onSubmitEvent,
        isCreatingEvent,
        isUpdatingEvent
    };
}

useNewEvent.propTypes = {};

export default useNewEvent;
